









































import { Component } from "vue-property-decorator";
import BaseNestedNavComponent from "../components/BaseNestedNavComponent";
import ActionButtonComponent from "../components/ActionButtonComponent.vue";

import { getProject, getProjectPayees, searchExpenses, searchIndigenousEmployments } from "../store/rest.service";
import { AffiliatedVendorsMeta, CommonMeta, Employment, Expense, Project, ResourceSearchResult, ShortRecord, SortColumnMapping, TotalsMeta, Vendor } from "@/store/models";
import { EmptyAffiliatedVendorsMeta, EmptyAggSearchCriteria, EmptyGeneralSearchCriteria, EmptyPayeeSearchCriteria, EmptySearchExpenses, EmptySearchIndigenousEmployments } from "@/store/models-empty";
import * as _ from "lodash";
import PayeeHelper from "./PayeeHelper";
import { VendorCategory } from "./PayeeHelper";
import ProjectHelper from "./ProjectHelper";
import moment from "moment";
import AggregateHelper from "./AggregateHelper";

@Component({
  components: {
    ActionButtonComponent,
  },
})
export default class NestedNavComponent extends BaseNestedNavComponent {
  public isLoadingProcurement = false;
  public isLoadingEmployment = false;
  public currentOrg: string = this.$store.getters["local/CURRENT_ORG"];
  private helper = new PayeeHelper(VendorCategory.None);
  private aggregateHelper: AggregateHelper = new AggregateHelper();
  private projectHelper = new ProjectHelper();

  private exportProcurementCSV() {
    this.isLoadingProcurement = true;
    const projectId = this.$route.params.id;

    this.getProcurementCSVData(projectId)
      .then(({ data, header, projectName }) => {
        this.downloadCSV(data, header, projectName, "procurement");
      })
      .finally(() => {
        this.isLoadingProcurement = false;
      });
  }

  private exportEmploymentCSV() {
    this.isLoadingEmployment = true;
    const projectId = this.$route.params.id;

    this.getEmploymentCSVData(projectId)
      .then(({ data, header, projectName }) => {
        this.downloadCSV(data, header, projectName, "employment");
      })
      .finally(() => {
        this.isLoadingEmployment = false;
      });
  }

  private async getProcurementCSVData(id: string) {
    this.projectHelper.getProject(id);
    this.helper.getIndigenousCommunities();
    this.helper.getDiversityReferenceData();
    const project = await getProject(id, true);
    const payees = await this.getProjectPayees(id);

    return this.parseProcurementCSVData(project, payees);
  }

  private async getEmploymentCSVData(id: string) {
    const project = await getProject(id, true);
    const employmentRecords = await this.getProjectEmploymentRecords(id);

    return this.parseEmploymentCSVData(project, employmentRecords);
  }

  private downloadCSV(data: any[][], header: string[], projectName: string, exportType: string) {
    let csv = header.join(",") + "\n";
    data.forEach((row) => {
      csv += row.join(",");
      csv += "\n";
    });

    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    anchor.target = "_blank";
    anchor.download = `export-${exportType}-${this.getCurrentOrgName(this.currentOrg)}-${projectName}-${moment().format("YYYY-MM-DD")}.csv`;
    anchor.click();
  }

  private async getProjectPayees(id: string) {
    const pagination: any = {
      sortBy: "common.displayName",
      descending: false,
      page: 1,
      rowsPerPage: 1000,
    };
    const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    const aggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
    const payeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);
    const sortMappings: SortColumnMapping[] = [
      {
        field: "common.displayName",
        sortColumn: "default",
      },
    ];

    const res = await getProjectPayees(this.mapSearchParams(pagination, "", sortMappings), id, searchCriteria, aggSearchCriteria, payeeSearchCriteria);

    return res.searchResults.results as Vendor[];
  }

  private async getProjectEmploymentRecords(id: string) {
    const pagination = {
      sortBy: "common.displayName",
      descending: false,
      page: 1,
      rowsPerPage: 500,
    };
    const searchCriteria = _.cloneDeep(EmptySearchIndigenousEmployments);
    searchCriteria.project = id;

    const sortMappings: SortColumnMapping[] = [
      {
        field: "common.displayName",
        sortColumn: "default",
      },
    ];

    const res = await searchIndigenousEmployments(this.mapSearchParams(pagination, "", sortMappings), searchCriteria);
    let records = res.searchResults.results as Employment[];
    const matched = res.searchResults.resultMeta.matched;
    const included = res.searchResults.resultMeta.included;

    if (matched > included) {
      // Request for each remaining page
      const numPages = Math.ceil((matched - included) / included);
      const requests: Array<Promise<ResourceSearchResult>> = [];

      for (let pageOffset = 1; pageOffset <= numPages; pageOffset++) {
        pagination.page = 1 + pageOffset;
        requests.push(searchIndigenousEmployments(this.mapSearchParams(pagination, "", sortMappings), searchCriteria));
      }

      const results = await Promise.all(requests);
      for (const result of results) {
        records = records.concat(result.searchResults.results as Employment[]);
      }
    }

    return records;
  }

  private async getPayeeExpenses(projectId: string, payeeId: string) {
    const expensesSearchCriteria = _.cloneDeep(EmptySearchExpenses);
    const pagination: any = {
      sortBy: "common.displayName",
      descending: false,
      page: 1,
      rowsPerPage: 1000,
    };
    const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    const sortMappings: SortColumnMapping[] = [
      {
        field: "common.displayName",
        sortColumn: "default",
      },
    ];

    expensesSearchCriteria.projects = [projectId];
    expensesSearchCriteria.payees = [payeeId];

    const res = await searchExpenses(this.mapSearchParams(pagination, "", sortMappings), searchCriteria, expensesSearchCriteria, true);

    return res.searchResults.results as Expense[];
  }

  private async parseProcurementCSVData(project: Project, payees: Vendor[]) {
    const header = [
      "Nisto Account Name",
      "Client Name",
      "Project Name",
      "Project ID",
      "Province / State",
      "Discipline",
      "Indigenous Project Partner",
      "Start Date",
      "Project Description",
      "Vendor / Supplier",
      "Expense Amount",
      "Expense Class",
      "Expense Date",
      "Expense Description",
      "Indigenous Community Category",
      "Indigenous Community",
      "Size",
      "Local",
    ];
    const supplyChainHeader = "Supply Chain Diversity";
    const csvRows: any[] = [];

    const projectId = (project.common as CommonMeta).identifier as string;

    this.projectHelper.setIsLoading();
    this.helper.setIsLoading();
    while (this.projectHelper.isLoading || this.helper.isLoading) {
      this.projectHelper.setIsLoading();
      this.helper.setIsLoading();
    }

    for (const payee of payees) {
      const payeeId = (payee.common as CommonMeta).identifier as string;
      const expenses = await this.getPayeeExpenses(projectId, payeeId);

      for (const expense of expenses) {
        const rowData = [
          this.getCurrentOrgName(this.currentOrg),
          project.project.creatorVendor ? (project.project.creatorVendor as ShortRecord).displayName : "",
          project.project.projectName,
          project.project.projectId,
          project.properties.province,
          project.properties.discipline,
          project.project.indigenousAffiliation ? (project.project.indigenousAffiliation as ShortRecord).displayName : "",
          project.project.startDate,
          project.project.description,
          payee.common ? payee.common.displayName : "",
          expense.payment.amount,
          expense.expense.expenseClass,
          expense.payment.effectiveDate,
          expense.payment.description,
          this.getDisplayNameFromShortRecords(payee.vendor.proxyPayeeFor as string, this.helper.ics) !== ""
            ? "Community Owned"
            : this.getPayeeAffiliation(payee.common ? (payee.common.identifier as string) : "").partnerDisplayName
            ? "Partnered"
            : "",
          this.getDisplayNameFromShortRecords(payee.vendor.proxyPayeeFor as string, this.helper.ics) !== ""
            ? this.getDisplayNameFromShortRecords(payee.vendor.proxyPayeeFor as string, this.helper.ics)
            : this.getPayeeAffiliation(payee.common ? (payee.common.identifier as string) : "").partnerDisplayName
            ? this.getPayeeAffiliation(payee.common ? (payee.common.identifier as string) : "").partnerDisplayName
            : "",

          payee.diversityDeclarations ? (payee.diversityDeclarations.impactStatus ? (payee.diversityDeclarations.impactStatus.values.length > 0 ? payee.diversityDeclarations.impactStatus.values[0] : "") : "") : "",
          this.getPayeeAffiliation(payee.common ? (payee.common.identifier as string) : "").isLocal ? "yes" : "no",
        ];

        const diversityStatements = this.helper.getSupplyChainDiversityDescription(payee.diversityDeclarations);
        diversityStatements.split(",").forEach((statement, index) => {
          const headerName = supplyChainHeader + " " + (index + 1);
          if (header.indexOf(headerName) === -1) {
            header.push(headerName);
          }
          rowData.push(statement);
        });

        csvRows.push(rowData);
      }
    }

    return { data: csvRows, header, projectName: project.project.projectName as string };
  }

  private async parseEmploymentCSVData(project: Project, employmentRecords: Employment[]) {
    const header = [
      "Nisto Account Name",
      "Client Name",
      "Project Name",
      "Project ID",
      "Province / State",
      "Discipline",
      "Indigenous Project Partner",
      "Start Date",
      "Project Description",
      "Date",
      "Hours Worked",
      "Rate",
      "Gross Wages",
      "Occupation",
      "Indigenous Community",
      "Designated Group",
    ];
    const csvRows: any[] = [];

    for (const employment of employmentRecords) {
      const rowData = [
        this.getCurrentOrgName(this.currentOrg),
        project.project.creatorVendor ? (project.project.creatorVendor as ShortRecord).displayName : "",
        project.project.projectName,
        project.project.projectId,
        project.properties.province,
        project.properties.discipline,
        project.project.indigenousAffiliation ? (project.project.indigenousAffiliation as ShortRecord).displayName : "",
        project.project.startDate,
        project.project.description,

        employment.payment.effectiveDate,
        employment.employment.manHours ? employment.employment.manHours : 0,
        employment.employment.hourlyRate,
        employment.payment.amount,
        employment.employment.occupationDescription ? employment.employment.occupationDescription.replaceAll(",", ";") : "",
        employment.diversity ? (employment.diversity.hasCommunityAffiliation as ShortRecord).displayName : "",
        employment.diversity ? (employment.diversity.isDiverse === "yes" || employment.diversity.isIndigenous === "yes" ? "yes" : "no") : "",
      ];

      csvRows.push(rowData);
    }

    return { data: csvRows, header, projectName: project.project.projectName as string };
  }

  private getPayeeAffiliation(payeeId: string): AffiliatedVendorsMeta {
    const emptyPayeeAffiliation = _.cloneDeep(EmptyAffiliatedVendorsMeta);
    if ((this.projectHelper.project as Project).affiliatedVendors) {
      const payeeAffiliation = ((this.projectHelper.project as Project).affiliatedVendors as AffiliatedVendorsMeta[]).find((x) => x.vendorId === payeeId);
      return payeeAffiliation !== undefined ? payeeAffiliation : emptyPayeeAffiliation;
    } else {
      return emptyPayeeAffiliation;
    }
  }
}
