import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';

@Component
export default class BaseNestedNavComponent extends BaseComponent {

  @Prop()
  protected menu: any;
  @Prop()
  protected query: any;
  @Prop()
  protected route: any;
  @Prop()
  protected actions: any;
  @Prop()
  protected reportLink: any;
  protected queryString: string = '';

  protected mounted() {
    this.mount();
  }

  protected isActiveMenuItem(href: string, index: number): boolean {
    let route: string = location.href.substr(location.href.indexOf('/#') + 2);
    const qs = route.indexOf('?');
    if (qs > -1) {
      route = route.substr(0, qs);
    }
    return href !== '' ? route.startsWith(this.route + href) : route === this.route;
  }

  protected navigate(code) {
    this.$emit('navigate', code);
  }

  protected refreshNav() {
    this.$emit('refresh');
  }

  protected navReport() {
    const path: string = this.reportLink as string;
    this.$router.push({ path });
  }

  protected mount() {
    if (this.query) {
      this.queryString = '';
      // tslint:disable-next-line
      for (const prop in this.query) {
        const op: string = this.queryString === '' ? '?' : '&';
        this.queryString += op + prop + '=' + this.query[prop];
      }
    }
  }
}
